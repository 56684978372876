<template>
    <a-layout>
        <a-layout-header style="background: #fff; padding: 0"/>
        <a-layout-content style="margin: 0 16px">
            <a-breadcrumb style="margin: 16px 0">
                <a-breadcrumb-item>首页</a-breadcrumb-item>
                <a-breadcrumb-item>文章列表</a-breadcrumb-item>
            </a-breadcrumb>
            <div class="toolsbar">
                <div style="float: left;margin-left: 20px;margin-top: 10px;">
                    <!--                    <a-button type="primary" @click="addadmin()">新建文章</a-button>-->
                    <a-button @click="gotoaddarticle()" type="primary">新建文章</a-button>
                    <!--                    <router-link to="/itemmanger">新建文章</router-link>-->

                    <a-button :loading="loading" @click="start" style="margin-left: 20px" type="primary">
                        刷新
                    </a-button>
                </div>
                <div class="serach_input">
                    <a-input-group compact>
                        <a-select defaultValue="标题">
                            <a-select-option value="title">标题</a-select-option>
                            <!--                    <a-select-option value="username">用户名</a-select-option>-->
                        </a-select>
                        <a-input-search @search="onSearch" defaultValue enterButton style="width: 64%"/>
                    </a-input-group>
                </div>
            </div>

            <div :style="{ padding: '24px', background: '#fff', minHeight: '360px' }">
                <!--                                {{data}}-->

                <a-table
                        :columns="columns"
                        :dataSource="data"
                        :loading="loading"
                        :pagination="pagination"
                        @change="onChange"
                        bordered
                        rowKey="id"
                >

                    <template slot="cover_img_json" slot-scope="text, index" style="text-align: center">
                        <span v-if="index.cover_images == null">暂无略缩图</span>
                        <span v-if="index.cover_images !== null"> <img :src="server_url+index.cover_images"
                                                                       width="260"/></span>


                    </template>
                    <template slot="name" slot-scope="text, index" style="text-align: center">
                        <!--                        {{index}}-->
                        <div class="artcleinfo"><span class="artcle">标题 :</span> <span
                                class="info">{{index.title}}</span></div>
                        <div class="artcleinfo"><span class="artcle">所属分类 :</span> <span class="info">{{JSON.parse(index.model_info_json).article_category_selectedOptions[JSON.parse(index.model_info_json).article_category_selectedOptions.length-1]}}</span>
                        </div>
                        <div class="artcleinfo"><span class="artcle">简介 :</span> <span class="info">{{index.short_content}}</span>
                        </div>
                        <div class="artcleinfo"><span class="artcle">发布时间 :</span> <span class="info">{{index.create_time}}</span>
                        </div>
                        <!--                        图片量:{{index.article_category_id}}<br/>-->
                        <!--                        {{Category[index.article_category_id].title}}<br/>-->
                        <!--                        {{Category}}<br/>-->

                    </template>

                    <template slot="operation" slot-scope="text, index">
                                                <span style="margin-right: 5px;">
                                                  <a @click="showModal(index)">编辑</a>
                                                </span>
                        <span>
                                                  <a @click="deleadmin(index)">剔除</a>
                                                </span>
                    </template>


                </a-table>
                <template>
                    <a-pagination size="small" v-model="current"/>
                </template>
            </div>
        </a-layout-content>
        <a-layout-footer style="text-align: center"></a-layout-footer>

    </a-layout>

</template>

<script>
    import Config from "../config";

    const columns = [
        {
            title: "id",
            dataIndex: "id",
            width: "5%",
            scopedSlots: {customRender: "id"}
        },
        {
            title: "略缩图",
            dataIndex: "cover_img_json",
            width: "10%",
            scopedSlots: {customRender: "cover_img_json"}
        },
        {
            title: "文章标题",
            dataIndex: "name",
            width: "auto",
            scopedSlots: {customRender: "name"}
        },
        {
            title: "作者",
            dataIndex: "author",
            width: "10%",
            scopedSlots: {customRender: "author"}
        },
        {
            title: "发布时间",
            dataIndex: "create_time",
            width: "10%",
            scopedSlots: {customRender: "create_time"}
        },
        {
            title: '操作',
            width: "7%",
            key: 'operation',
            scopedSlots: {customRender: 'operation'}
        },
    ];


    import {Modal} from "ant-design-vue";
    import Storage from "../common/storage";
    import moment from "moment";
    // import * as Util from "../common/util";

    export default {
        name: "Dashboard",
        components: {},
        data() {
            return {
                columns,
                data: [],
                returnInfo: [],
                pagination: {
                    pageSize: 10
                },
                current: 1,
                server_url: Config.data.server_url,
                popinfos: false,
                popadd: false,
                search_text: "",
                loading: false,
                add: {
                    login_name: "",
                    password: "",
                    re_password: "",
                    name: "",
                    sex: "",
                    mobile: "",
                    type: "",
                    group_id: "",
                },
                edit: {
                    uid: "",
                    login_name: "",
                    password: "",
                    re_password: "",
                    name: "",
                    sex: "2",
                    mobile: "",
                },
                Category: [],
            };
        },
        created() {
            // 获取第一页内容
            this.doSearch(1, this.pagination.pageSize, "", "", "", "1");
            this.getcategory();

        },
        methods: {

            async getcategory(pid) {
                this.loading = true;
                this.Category = [];
                console.log(Storage.uid)
                console.log(Storage.token)
                let now = Date.now();
                let result = await this.$get("/api/admin/articleCategory/list", {
                    params: {
                        token: Storage.token,
                        uid: Storage.uid,
                        pid: pid,
                        t: now,
                    }
                });

                if (result.status === true) {

                    this.Category = result.data;
                    this.loading = false;
                }

                this.loading = false;
            },
            gotoaddarticle() {
                window.vue.$router.push({path: "/addspecial",});
            },
            start() {
                this.loading = true;
                this.data = [];
                // ajax request after empty completing
                setTimeout(() => {
                    this.loading = false;
                    this.selectedRowKeys = [];
                }, 1000);
                this.doSearch(1, this.pagination.pageSize, "", "", "", "1");
            },

            async onSearch(text) {
                this.data = []
                this.search_text = text;
                this.doSearch(1, this.pagination.pageSize, text, "", "", "1");
            },

            async onChange(pagination) {
                console.log(pagination);
                // await this.doSearch(1, this.pagination.pageSize, text,"", "", "1");
                await this.doSearch(
                    pagination.current,
                    pagination.pageSize,
                    this.search_text,
                    "",
                    "",
                    "1",
                );
            },

            async onChanges(e) {
                console.log(e.target.value);
            },

            showModal(item) {
                console.log(item)
                window.vue.$router.push({
                    path: "/editspecial?id=",
                    query: {
                        id: item.id,
                    }
                });
                // this.edit.login_name = item.login_name;
                // this.edit.password = item.password;
                // this.edit.re_password = item.re_password;
                // this.edit.name = item.name;
                // this.edit.sex = item.sex.toString();
                // this.edit.mobile = item.mobile;
                // this.edit.uid = item.uid;
                // this.edit.type = 2;
                // this.popinfos = true;
            },
            onShowSizeChange(current, pageSize) {
                console.log(current, pageSize);
            },
            async doSearch(page, limit, search, start_time, end_time, status) {
                this.loading = true;
                this.data = [];
                console.log(Storage.uid)
                console.log(Storage.token)
                let now = Date.now();
                let result = await this.$get("/api/admin/article/list", {
                    params: {
                        token: Storage.token,
                        uid: Storage.uid,
                        page: page,
                        limit: limit,
                        search: search,
                        start_time: start_time,
                        end_time: end_time,
                        status: status,
                        type: 4,
                        t: now,
                    }
                });

                if (result.status === true) {
                    let data = [...this.data];
                    for (let i in result.data.list) {
                        let info = result.data.list[i];
                        let offset = (parseInt(page) - 1) * limit + parseInt(i);
                        // console.log(offset)

                        data[offset] = {
                            id: info.id,
                            title: info.title,
                            article_category_id: info.article_category_id,
                            sku_list: info.sku_list,
                            cover_images: info.cover_images,
                            author: info.author,
                            // create_time: info.create_time,
                            model_info_json: info.model_info_json,
                            short_content: info.short_content,
                            // cover_img_json: JSON.parse(info.cover_img_json)[0],
                            // login_name: info.login_name,
                            create_time: moment(info.create_time * 1000).format("YYYY/MM/DD hh:mm:ss"),
                            more_info_json: info.more_info_json,

                            status: info.status,
                        };
                    }

                    let pagination = {...this.pagination};
                    pagination.total = result.data.total;
                    this.pagination = pagination;
                    this.data = data;
                    this.loading = false;
                }

                this.loading = false;
            },
            async deleadmin(item) {
                var that = this;
                console.log(item);
                Modal.confirm({
                    title: "警告！",
                    content: "是否确认剔除该文章?",

                    onOk: async function () {

                        let result = await that.$post("/api/admin/article/delete", {

                            uid: Storage.uid,
                            token: Storage.token,
                            id: item.id,
                            // notes: this.edit_pop.notes,
                            t: Date.now(),
                            // sign: Crypt.sign([
                            //     Storage.token,
                            //     this.edit_pop.id,
                            //     JSON.stringify(data),
                            //     this.edit_pop.notes,
                            //     this.$config.secret_key,
                            //     now
                            // ])
                        });
                        console.log(result);
                        if (result.status == true) {
                            console.log("准备弹窗！");
                            that.data = []
                            await that.doSearch(1, that.pagination.pageSize, "", "", "", "1");


                            Modal.info({
                                title: "剔除成功"
                            });
                        }
                        if (result.status === false) {
                            // that.data = []
                            // await this.doSearch(this.page, "1", "", this.estatedefault, "");
                            Modal.info({
                                title: result.msg
                            });
                        }
                    }

                });


            },

        }
    };
</script>
<style scoped>
    .editable-row-operations a {
        margin-right: 8px;
    }

    #components-layout-demo-custom-trigger .trigger {
        font-size: 18px;
        line-height: 64px;
        padding: 0 24px;
        cursor: pointer;
        transition: color 0.3s;
    }

    #components-layout-demo-custom-trigger .trigger:hover {
        color: #1890ff;
    }

    #components-layout-demo-custom-trigger .logo {
        height: 32px;
        background: rgba(255, 255, 255, 0.2);
        margin: 16px;
    }

    .serach_input {
        width: 25vw;
        float: right;
        margin-top: 10px;
        margin-bottom: 10px;
        text-align: right;
        margin-right: 25px;
    }

    .pages {
        float: right;
        margin-top: 10px;
    }

    .toolsbar {
        display: block;
        width: 100%;
        height: 42px;
        background: #fff
    }

    .artcleinfo {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 5px;
    }

    .artcle {
        text-align: right;
        display: block;
        width: 15%;
        color: #ee5555;
        font-weight: bold;
    }

    .info {
        text-align: left;
        width: 85%;
        display: block;
    }
</style>
